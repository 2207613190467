import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";

import { ReportOperatorSalary } from "../../../models/ReportOperatorSalary";
import axiosClient from "../../../config/axios";

import OperatorSalaryForm from "../../../forms/OperatorSalaryForm";
import ReportOperatorSalarySchema from "../../../validations/ReportOperatorSalarySchema";
import TableOperatorSalary from "../../../components/reports/TableOperatorSalary";
import Loader from "../../../components/loader";

const OperatorSalary = () => {
  const [loading, setLoading] = React.useState(true);
  const [records, setRecords] = React.useState([]);
  const [orders, setOrders] = React.useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: ReportOperatorSalary(),
    validationSchema: ReportOperatorSalarySchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const adjustedStart = moment(values.start)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const adjustedEnd = moment(values.end)
          .add(1, "days")
          .format("YYYY-MM-DD");

        // const endpoint = `/reports/operator-salary?start=${adjustedStart}&end=${adjustedEnd}`;
        const endpoint = `/reports/operator-salary?start=${values.start}&end=${values.end}`;

        const { data } = await axiosClient.get(endpoint);
        setRecords(data);
        const dataTable = [];
        data?.map((record) => {
          const { operador } = record;
          record.orders.map((order) => {
            const { Order } = order;

            dataTable.push({
              ...Order,
              operador: operador,
              totalDistance: order.totalDistance,
            });
          });
        });

        setOrders(dataTable);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  React.useEffect(() => {
    formik.handleSubmit();
  }, []);

  return (
    <React.Fragment>
      <Loader isLoading={loading} />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Inicio</Link>
            </li>
            <li className="breadcrumb-item active">Reporte de salario</li>
          </ol>
          <h1 className="page-header mb-0">Reporte de salario</h1>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-xl-12 card border-0 p-3">
          <OperatorSalaryForm formik={formik} records={records} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 card border-0 p-3">
          <TableOperatorSalary
            orders={orders}
            start={formik.values.start}
            end={formik.values.end}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OperatorSalary;
